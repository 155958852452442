<template>
  <div class="vehicle-availability-sidebar">
    <div class="vehicle-availability-sidebar--content">
      <v-form ref="form">
        <template v-if="isModeAdd && !isAffiliateView">
          <div>
            <CRSelect
              v-model="selectedMarketId"
              label="Market"
              placeholder="Market"
              :items="markets"
              item-text="marketName"
              item-value="marketId"
              @change="selectedMarket"
            />
          </div>
          <div>
            <CRSelect
              v-model="selectedPartnerTypeIds"
              :menu-props="{ contentClass: 'cr-select--multiple--no-margin' }"
              label="Partner Type(s)"
              placeholder="Partner Type(s)"
              :items="partnerTypes"
              item-text="label"
              item-value="id"
              multiple
              :all="areAllPartnerTypesSelected"
            >
              <template #prepend-item>
                <v-list-tile
                  style="cursor: pointer"
                  @click="toggleAllPartnerTypesSelected"
                >
                  <v-checkbox
                    :value="areAllPartnerTypesSelected"
                    hide-details
                    class="ma-0 pa-0"
                    style="width: 100%:"
                    @click.prevent
                  />
                  <span style="flex: 14">All</span>
                </v-list-tile>
              </template>
            </CRSelect>
          </div>
          <div>
            <CRSelect
              v-model="selectedCompanyIds"
              :menu-props="{ contentClass: 'cr-select--multiple--no-margin' }"
              label="Affiliate(s)"
              placeholder="Affiliate(s)"
              :items="filteredAffiliatesList"
              item-text="name"
              item-value="companyId"
              :loading="areAffiliatesLoading"
              multiple
              :all="areAllAffiliatesSelected"
              :rules="[
                (val) => !!selectedCompanyIds.length || 'Affiliate is Required',
                (val) =>
                  checkAffiliateVehicleTypes() ||
                  'Selected providers do not have the selected vehicle type.',
              ]"
            >
              <template v-if="filteredAffiliatesList.length" #prepend-item>
                <v-list-tile
                  style="cursor: pointer"
                  @click="toggleAllAffiliatesSelected"
                >
                  <v-checkbox
                    :value="areAllAffiliatesSelected"
                    hide-details
                    class="ma-0 pa-0"
                    style="width: 100%:"
                    @click.prevent
                  />
                  <span style="flex: 14">All</span>
                </v-list-tile>
              </template>
            </CRSelect>
          </div>
          <v-divider />
        </template>
        <div v-if="isTypeBlock || (isVehicleBlock && createdByCharterUp) ">
          <CRSelect
            v-model="selectedVehicleTypeId"
            label="Vehicle Type"
            placeholder="Vehicle Type"
            :items="vehicleTypes"
            item-text="label"
            item-value="id"
            :disabled="isModeEdit"
          />
          <div>
            <CRSelect
              v-if="!areAffiliatesLoading && (selectedCompanyIds.length === 1 || (!isModeAdd && companyId !== null)) && selectedVehicleTypeId !== null && (isModeAdd  || (isModeEdit && isVehicleBlock))"
              v-model="selectedVehicleIds"
              label="Vehicle Name"
              placeholder="Vehicle Name"
              multiple
              :items="filteredAffiliateVehicles"
              :all="areAllVehiclesSelected"
              item-text="vehicleName"
              item-value="vehicleId"
              :disabled="isModeEdit"
            >
              <template v-if="filteredAffiliateVehicles.length && isModeAdd" #prepend-item>
                <v-list-tile
                  style="cursor: pointer"
                  @click="toggleAllVehiclesSelected"
                >
                  <v-checkbox
                    :input-value="areAllVehiclesSelected"
                    hide-details
                    class="ma-0 pa-0"
                    style="width: 100%:"
                    @click.prevent
                  />
                  <span style="flex: 14" :style="areAllVehiclesSelected ? `color: ${$cr.theme.primary}` : ''">All</span>
                </v-list-tile>
              </template>
            </CRSelect>
          </div>
        </div>
        <div v-else>
          <p>
            The vehicle has been marked unavailable by the operator at this
            time.
          </p>
        </div>
        <div>
          <CRSelect
            v-model="selectedReasonTypeId"
            label="Reason Type"
            placeholder="Reason Type"
            :items="reasonTypes"
            item-text="reasonLabel"
            item-value="availabilityBlockReasonId"
            validate-on-blur
            :disabled="isModeView"
            :rules="[(val) => !!val || 'Reason Type is Required']"
          />
        </div>
        <v-flex>
          <v-layout align-center>
            <v-flex style="padding-right: 5px">
              <CRInput
                v-model="startTime"
                label="Start Date"
                type="date"
                :disabled="isModeView"
              />
            </v-flex>
            <v-flex style="padding-left: 5px">
              <CRInput
                v-model="endTime"
                label="End Date"
                type="date"
                :disabled="isModeView"
                :rules="[
                  (val) => validateCurrentDates() || 'Invalid Date Range',
                ]"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-form>
      <div v-if="isModeEdit || isModeView">
        <p>
          <b>{{ availabilityBlock.updatedOn ? 'Updated by' : 'Created by' }}</b>
          {{ userLogText }}
        </p>
      </div>
    </div>
    <div class="cr-sidebar-dialog--button-spacer" />
    <template v-if="isModeEdit">
      <div class="cr-sidebar-dialog--button-spacer" />
      <CRButton
        id="vehicle-availability-sidebar-delete-btn"
        class="vehicle-availability-sidebar--delete-btn"
        text-color="red"
        @click="submitDeleteAvailabilityBlock"
      >
        Delete
      </CRButton>
    </template>
    <template v-if="!isModeView">
      <CRButton
        id="vehicle-availability-save-btn"
        :loading="submitting"
        class="vehicle-availability-sidebar--action-btn"
        color="primary"
        @click="submitAvailabilityBlock"
      >
        Save
      </CRButton>
    </template>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import affiliates from '@/services/affiliates'
import { EventBus } from '@/utils/event-bus'
import { mapActions } from 'vuex'
import { isoToString } from '@/utils/time'
import { deepClone } from '@/utils/deepClone'
import { filter } from '@/utils/filter'
import availability from '@/services/availability'
import markets from '@/services/markets'

const CHARTERUP_COMPANY_ID = 2

export default {
  props: {
    mode: {
      type: String,
      default: 'add',
    },
    availabilityBlock: {
      type: Object,
      default: () => ({}),
    },
    companyId: {
      type: Number,
      default: null,
    },
    isVehicleBlock: {
      type: Boolean,
      default: false,
    },
    isTypeBlock: {
      type: Boolean,
      default: false,
    },
    isAffiliateView: {
      type: Boolean,
    },
  },
  data() {
    return {
      vehicleTypes: [],
      selectedVehicleTypeId: null,
      reasonTypes: [],
      selectedReasonTypeId: null,
      startTime: DateTime.local().toISODate(),
      endTime: DateTime.local().toISODate(),
      submitting: false,
      markets: [],
      selectedMarketId: null,
      partnerTypes: [],
      selectedPartnerTypeIds: [],
      affiliates: [],
      mappedAffiliates: [],
      selectedCompanyIds: [],
      areAffiliatesLoading: false,
      affiliateVehicles: [],
      selectedVehicleIds: [],
    }
  },
  watch: {
    selectedVehicleTypeId(newValue, oldValue) {
      this.selectedVehicleIds = []
    },
    selectedCompanyIds(newCompanyIds) {
      this.selectedVehicleIds = []
      if (!this.areAffiliatesLoading && newCompanyIds.length === 1) {
        this.getAffiliateVehicles()
      }
    },
    areAffiliatesLoading(loading) {
      if (!loading && this.selectedCompanyIds.length === 1) {
        this.getAffiliateVehicles()
      }
    }
  },
  computed: {
    userLogText() {
      const userName = this.availabilityBlock.updatedByName
        ? this.availabilityBlock.updatedByName
        : this.availabilityBlock.createdByName
      const time = this.availabilityBlock.updatedOn
        ? this.availabilityBlock.updatedOn
        : this.availabilityBlock.createdOn
      return (
        userName +
        ' ' +
        isoToString(time, Intl.DateTimeFormat().resolvedOptions().timeZone)
      )
    },
    isModeAdd() {
      return this.mode === 'add'
    },
    isModeView() {
      return this.mode === 'view'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    areAllPartnerTypesSelected() {
      const allPartnerTypeIds = this.partnerTypes.map((pt) => pt.id)
      if (this.selectedPartnerTypeIds.length === allPartnerTypeIds.length) {
        return allPartnerTypeIds.every((element) =>
          this.selectedPartnerTypeIds.includes(element)
        )
      }
      return false
    },
    areAllAffiliatesSelected() {
      const allFilteredCompanyIds = this.filteredAffiliatesList.map(
        (a) => a.companyId
      )
      if (this.selectedCompanyIds.length === allFilteredCompanyIds.length) {
        return allFilteredCompanyIds.every((element) =>
          this.selectedCompanyIds.includes(element)
        )
      }
      return false
    },
    areAllVehiclesSelected() {
      return this.selectedVehicleIds.length === this.filteredAffiliateVehicles.length
    },
    filteredAffiliatesList() {
      let filteredAffiliates = this.mappedAffiliates.length
        ? deepClone(this.mappedAffiliates)
        : deepClone(this.affiliates)

      const currentCompanyIndex = filteredAffiliates.findIndex(
        (fa) => fa.companyId === this.companyId
      )
      if (currentCompanyIndex > -1) {
        const currentCompany = filteredAffiliates.splice(currentCompanyIndex, 1)
        filteredAffiliates.unshift(...currentCompany)
      }

      if (this.selectedPartnerTypeIds.length) {
        filteredAffiliates = filteredAffiliates.filter((fa) =>
          this.selectedPartnerTypeIds.includes(fa.partnerTypeId)
        )
      }
      return filteredAffiliates
    },
    filteredAffiliateVehicles() {
      return this.affiliateVehicles.filter((v) => v.vehicleType.id === this.selectedVehicleTypeId)
    },
    createdByCharterUp() {
      return this.availabilityBlock.createdByCompanyId === CHARTERUP_COMPANY_ID
    }
  },
  async mounted() {
    await this.getVehicleTypes()
    this.getReasonTypes()
    if (this.isModeEdit || this.isModeView) {
      this.selectedVehicleTypeId = this.availabilityBlock.vehicleTypeId
      this.selectedReasonTypeId = this.availabilityBlock.reasonTypeId
      this.startTime = this.availabilityBlock.startDate
      this.endTime = this.availabilityBlock.endDate
      await this.getAffiliateVehicles()
      if (this.availabilityBlock.vehicleId !== null) {
        this.selectedVehicleIds.push(this.availabilityBlock.vehicleId)
      }
    } else {
      if (this.companyId) {
        this.selectedCompanyIds = [this.companyId]
      }
      if (!this.isAffiliateView) {
        this.getMarkets()
        this.getPartnerTypes()
        this.getAffiliates()
      }
      this.selectedVehicleTypeId = null
      this.selectedReasonTypeId = null
    }
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
    }),
    async getAffiliates() {
      this.areAffiliatesLoading = true
      const res = await affiliates.getAffiliates({
        pageSize: -1,
      })
      this.affiliates = res.data.resultList
      this.areAffiliatesLoading = false
    },
    async getMapAffiliates() {
      this.areAffiliatesLoading = true
      const filterObject = {
        column: {
          _t_id: '9b56f4c2-43c1-412d-85ce-112c2b633606',
          prop: 'nearestMarketId',
          filterType: 'eq',
        },
      }
      if (!this.selectedMarketId) {
        return
      }
      filterObject.value = this.selectedMarketId
      const nearestMarketSearch = filter()
      const parentFilter = nearestMarketSearch.createParent('and')
      nearestMarketSearch.add(parentFilter, filterObject)

      const params = {
        filters: nearestMarketSearch.asQueryParams(),
        pageSize: -1,
      }

      const res = await affiliates.getAffiliatesMap(params).catch((error) => {
        return false
      })
      this.mappedAffiliates = res.data.resultList
      this.areAffiliatesLoading = false
    },
    async getMarkets() {
      const res = await markets.tableView({
        pageSize: -1,
      })
      this.markets = res.data.resultList.sort((a, b) =>
        a.marketName > b.marketName ? 0 : -1
      )
    },
    async getPartnerTypes() {
      const res = await this.$store.dispatch('types/getPartnerTypes', {
        pageSize: -1,
      })
      this.partnerTypes = res.data.resultList.filter(
        (pt) => !(pt.id === 1 || pt.id === 2)
      )
    },
    async getVehicleTypes() {
      const res = await this.$store.dispatch('vehicles/getVehicleTypes', {
        page: 1,
        pageSize: -1,
      })
      this.vehicleTypes = res.data.resultList
    },
    async getReasonTypes() {
      const res = await availability.availabilityBlockReasonType()
      this.reasonTypes = res.data.reasonTypes
    },
    toggleAllPartnerTypesSelected() {
      this.selectedPartnerTypeIds = this.areAllPartnerTypesSelected
        ? []
        : deepClone(this.partnerTypes.map((pt) => pt.id))
    },
    toggleAllAffiliatesSelected() {
      this.selectedCompanyIds = this.areAllAffiliatesSelected
        ? []
        : deepClone(this.filteredAffiliatesList.map((a) => a.companyId))
    },
    toggleAllVehiclesSelected() {
      this.selectedVehicleIds = this.areAllVehiclesSelected ? [] : deepClone(this.filteredAffiliateVehicles.map((v) => v.vehicleId))
    },
    selectedMarket() {
      if (this.selectedMarketId) {
        this.getMapAffiliates()
      } else {
        this.mappedAffiliates = []
      }
    },
    updateVehicleType(vehicleTypeId) {
      this.selectedVehicleTypeId = vehicleTypeId
    },
    async getAffiliateVehicles() {
      const companyId = this.selectedCompanyIds[0] || this.companyId
      const res = await this.$store.dispatch(
        'vehicles/getAffiliateSummaryVehicles',
        companyId
      )
      this.affiliateVehicles = res?.data?.vehicles
    },
    validateCurrentDates() {
      return (
        DateTime.fromISO(this.startTime) <= DateTime.fromISO(this.endTime) &&
        DateTime.fromISO(this.endTime) >= DateTime.fromISO(this.startTime)
      )
    },
    checkAffiliateVehicleTypes() {
      const affiliates = this.mappedAffiliates?.length
        ? deepClone(this.mappedAffiliates).filter((affiliate) =>
            this.selectedCompanyIds.includes(affiliate.companyId)
          )
        : deepClone(this.affiliates).filter((affiliate) =>
            this.selectedCompanyIds.includes(affiliate.companyId)
          )
      if (!this.selectedVehicleTypeId) {
        return true
      }
      return !affiliates.every((affiliate) =>
        affiliate?.ownedVehicleTypes
          ? !affiliate?.ownedVehicleTypes
              .split('|')
              .includes(this.selectedVehicleTypeId.toString())
          : true
      )
    },
    async submitAvailabilityBlock() {
      this.submitting = true
      const form = this.$refs['form']
      if (!form.validate()) {
        this.submitting = false
        return
      }

      let resetBlockIds = [this.availabilityBlock?.availabilityBlockId]
      if (this.selectedVehicleIds.length > 0 && !this.areAllVehiclesSelected && (this.selectedCompanyIds.length === 1 || (!this.isModeAdd && this.companyId !== null))) {
        try {
          if (this.isModeAdd) {
            const payload = {
              startDatetime: DateTime.fromISO(this.startTime).toUTC(),
              endDatetime: DateTime.fromISO(this.endTime).toUTC(),
              reasonTypeId: this.selectedReasonTypeId,
              companyId: this.selectedCompanyIds[0],
              availabilityBlockId: null,
              createdFromDateRange: true,
            }
            const results = await Promise.all(
              this.selectedVehicleIds.map((vi) => {
                const params = { payload: deepClone(payload) }
                params.payload.vehicleId = vi
                return availability
                  .createVehicleAvailabilityBlock(params)
                  .catch((e) => console.error(e))
              })
            )
            resetBlockIds = resetBlockIds.concat(
              results[0]?.data?.conflictingBlockIds
            )
            const validResults = results.filter(
              (result) => !(result instanceof Error)
            )
            setTimeout(() => {
              this.showAlert({
                type: 'success',
                message: `${validResults.length} out of ${results.length} availability blocks created successfully.`,
              })
            }, 2000)
          } else if (this.isModeEdit) {
            const params = {
              payload: {
                startDatetime: DateTime.fromISO(this.startTime).toUTC(),
                endDatetime: DateTime.fromISO(this.endTime).toUTC(),
                reasonTypeId: this.selectedReasonTypeId,
                companyId: this.companyId,
                availabilityBlockId: this.availabilityBlock.availabilityBlockId,
                createdFromDateRange: true,
              }
            }
            const res = await availability.editVehicleAvailabilityBlock(params)
            resetBlockIds = resetBlockIds.concat(res?.data?.conflictingBlockIds)
          }
          EventBus.$emit(
            'reload-vehicle-availability',
            this.startTime,
            this.endTime,
            resetBlockIds
          )
        } catch (error) {
          console.error(error)
          this.showAlert({
            type: 'error',
            message: 'There may be a date conflict with your unavailability block.',
          })
          this.submitting = false
          return
        }
      } else {
        const payload = {
          vehicleTypeId: this.selectedVehicleTypeId,
          startDatetime: DateTime.fromISO(this.startTime).toUTC(),
          endDatetime: DateTime.fromISO(this.endTime).toUTC(),
          reasonTypeId: this.selectedReasonTypeId,
          availabilityBlockId: null,
        }
        try {
          if (this.isModeAdd) {
            const results = await Promise.all(
              this.selectedCompanyIds.map((sci) => {
                const params = { payload: deepClone(payload) }
                params.payload.companyId = sci
                return availability
                  .createVehicleTypeAvailabilityBlock(params)
                  .catch((e) => console.error(e))
              })
            )
            resetBlockIds = resetBlockIds.concat(
              results[0]?.data?.conflictingBlockIds
            )
            const validResults = results.filter(
              (result) => !(result instanceof Error)
            )
            this.showAlert({
              type: 'success',
              message: `${validResults.length} out of ${results.length} availability blocks created successfully.`,
            })
          } else if (this.isModeEdit) {
            const params = { payload: deepClone(payload) }
            params.payload.companyId = this.companyId
            params.payload.availabilityBlockId = this.availabilityBlock.availabilityBlockId
            const res = await availability.editTypeVehicleAvailabilityBlock(
              params
            )
            resetBlockIds = resetBlockIds.concat(res?.data?.conflictingBlockIds)
          }
          EventBus.$emit(
            'reload-vehicle-type-availability',
            this.startTime,
            this.endTime,
            resetBlockIds
          )
        } catch (error) {
          this.showAlert({
            type: 'error',
            message:
              'There may be a date conflict with your unavailability block.',
          })
          this.submitting = false
          return
        }
      }

      this.submitting = false
      this.close()
    },
    async submitDeleteAvailabilityBlock() {
      const form = this.$refs['form']
      if (!form.validate()) {
        return
      }
      this.submitting = true
      const params = {
        payload: {
          availabilityBlockId: this.availabilityBlock.availabilityBlockId,
        },
      }
      try {
        let res
        if (this.availabilityBlock.isVehicleAvailabilityBlock) {
          res = await availability.deleteVehicleAvailabilityBlock(params)
        } else {
          const res = await availability.deleteVehicleTypeAvailabilityBlock(
            params
          )
        }
      } catch (error) {
        this.showAlert({
          type: 'error',
          message: error,
        })
        this.submitting = false
        return
      }
      this.submitting = false
      EventBus.$emit(
        'delete-availability',
        this.availabilityBlock.availabilityBlockId
      )
      this.close()
    },
    close() {
      this.$store.dispatch('app/closeDialog')
    },
  },
}
</script>
<style lang="scss" scoped>
.vehicle-availability-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--delete-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 71px;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }
}
</style>
